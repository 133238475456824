<template>
  <div id="app">
    <router-view></router-view>
    <!-- <RentHouse /> -->
    <!-- <HouseDetail /> -->
  </div>
</template>

<script>
// import RentHouse from './components/RentHouse.vue';
// import HouseDetail from './components/HouseDetail.vue';

export default {
  name: 'App',
  components: {
    // RentHouse,
    // HouseDetail,
  },
};
</script>

<style></style>
