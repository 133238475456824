import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
const routes =  [
    {
        path: '/',
        component: () => import('@/components/RentHouse.vue'),
        name:'RentHouse',
    },
    {
        path: '/HouseDetail',
        component: () => import('@/components/HouseDetail.vue'),
        name:'HouseDetail', 
    },
    {
        path: '/houseRentDetail',
        component: () => import('@/components/houseRentDetail.vue'),
        name:'houseRentDetail', 
    }
]
const router = new VueRouter({
    routes
  })
  export default router